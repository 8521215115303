// 画像系
const BASE_IMAGE_PATH = "/others";
export const BG_WAVE_PATH = `${BASE_IMAGE_PATH}/bg-auth-wave.svg`;
export const samplePlaceholderPath = (width: number, height: number) => `https://placehold.jp/${width}x${height}.png`;
export const GOOGLE_DRIVE_SVG_PATH = `${BASE_IMAGE_PATH}/Drive.svg`;
export const DROPBOX_SVG_PATH = `${BASE_IMAGE_PATH}/Dropbox.svg`;
export const DISCORDBANNER_SVG_PATH = `${BASE_IMAGE_PATH}/DiscordBannerLogo.svg`;

// 本番、画像系
const PRD_BASE_IMAGE_PATH = "/images";
export const SIGN_UP_LAST_STEP_CONTENTS_PATH = `${PRD_BASE_IMAGE_PATH}/signup-desktop-step-3-image.png`;
export const SIGN_UP_STEP_3_ALERT_PATH = `${PRD_BASE_IMAGE_PATH}/signup-step-3-Alert.svg`;
export const SIGN_UP_STEP_3_IMAGE_PATH = `${PRD_BASE_IMAGE_PATH}/signup-step-3-image.png`;
export const SERVICE_MAIN_LOGO_PATH = `${PRD_BASE_IMAGE_PATH}/service-main-logo.png`;
export const SERVICE_OGP_IMAGE_PATH = `${PRD_BASE_IMAGE_PATH}/service-ogp-img.png`;
export const BENCHMARK_VIDEO_THUMBNAIL_PATH = `${PRD_BASE_IMAGE_PATH}/benchmark_video_thum_none.png`;

// 資料系 ヘルプページ , 利用規約, プライバシーポリシー
const BASE_SERVICE_PATH = "https://hinome.notion.site/hinome/New_-258ada4a6e34463786f49a68c91e6f39";
export const SUPPORT_SERVICE_PATH = `${BASE_SERVICE_PATH}/`;
export const SERVICE_TERMS_PATH = "https://hinome.notion.site/1314a38f7678469e802bdf74513cb8f4";
export const PRIVACY_POLICY_PATH = "https://hinome.notion.site/99b02af210f64b2ab68eda4081673b5d";

// Zendesk メールアドレス
export const ZENDESK_EMAIL_PATH = "support@hinome.zendesk.com";
// SendGrid メールアドレス
export const SEND_GRID_EMAIL_PATH = "app@hinome.co.jp";
export const SEND_GRID_DASHBOARD_PATH = "https://app.sendgrid.com/";

// InstagramビジネスアカウントとFacebookページを連携する。
export const INSTAGRAM_BUSINESS_SETTING_PATH =
  "https://www.facebook.com/business/learn/lessons/create-an-instagram-business-profile";
export const FACEBOOK_PAGE_SETTING_PATH = "https://www.facebook.com/pages/creation/?ref_type=facebook_business_website";
export const LINKED_INSTAGRAM_FACEBOOK_PATH = "https://ja-jp.facebook.com/business/help/898752960195806";

// Instagramのベースパス
export const BASE_INSTAGRAM_SERVICE = "https://www.instagram.com/";
export const EDIT_INSTAGRAM_PATH = `${BASE_INSTAGRAM_SERVICE}accounts/edit/`;

// 管理者ダッシュボード系のパス
export const ADMIN_LOGIN_PATH = "/admin";
export const ADMIN_DASHBOARD_PATH = "/admin/dashboard";
export const ADMIN_USER_TABLE_PATH = "/admin/user-table";
export const ADMIN_INSTAGRAM_TABLE_PATH = "/admin/instagram-table";
export const ADMIN_FEED_TABLE_PATH = "/admin/feed-table";
export const ADMIN_QUESTION_PATH = "/admin/question";
export const ADMIN_LEFT_AD_CONTENTS_MANAGER = "/admin/left-ad-contents-manager";
export const ADMIN_SEND_GRID_PATH = "/admin/sendgrid";
export const ADMIN_ORGANIZATION_PATH = "/admin/organization";
export const ADMIN_ORGANIZATION_ID_PATH = (id: string) => `/admin/organization/${id}`;
export const ADMIN_WAVE_PATH = `${BASE_IMAGE_PATH}/polygon-scatter-haikei.svg`;
export const ADMIN_GOOGLE_ANALYTICS_PATH =
  "https://console.firebase.google.com/u/0/project/hinome-pj-prd/analytics/app/web:MTdkYzhjMGEtMTlmMS00OTg2LTkwYTQtNWM3NWZhMzY3Njc3/overview/~2F%3Ft%3D1652324485246&fpn%3D379766972260&swu%3D1&sgu%3D1&sus%3Dupgraded&cs%3Dapp.m.dashboard.overview&g%3D1";

export const ADMIN_CLARITY_PATH = "https://clarity.microsoft.com/projects";

// Corporate・組織向けダッシュボード系のパス
export const CORPORATE_LOGIN_PATH = "/corporate";
export const CORPORATE_DASHBOARD_PATH = "/corporate/dashboard";
export const CORPORATE_USER_TABLE_PATH = "/corporate/user-table";
export const CORPORATE_INSTAGRAM_TABLE_PATH = "/corporate/instagram-table";
export const CORPORATE_LABEL_TABLE_PATH = "/corporate/label-table";
export const IMG_CORPORATE_BACKGROUND = `${BASE_IMAGE_PATH}/corporate/background-haikei.svg`;
export const IMG_CORPORATE_LOGO_AMIGO = `${BASE_IMAGE_PATH}/corporate/logo-amigo.png`;

// Canvaのパス
export const COMMUNITY_PATH = "https://service.hinome.app/community-hinome";

// Hinome-replyのパス
export const HINOME_REPLY_PATH = "https://service.hinome.app/hinome-reply";

// Discordのパス
export const DISCORD_PATH = "https://buy.stripe.com/3csdRk1D36A0crmfYZ?client_reference_id=hinome";
